import { inject, Injectable } from '@angular/core';
import { connectFunctionsEmulator, Functions, httpsCallable } from '@angular/fire/functions';
import { environment } from '../../environments/environment';
import { OfficeSubscription } from '../core/models/office';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private functions: Functions = inject(Functions);
  public subscription: OfficeSubscription;

  constructor() {
    //this.functions.region = "europe-west3";
    if(environment.useEmulators.http) {
      connectFunctionsEmulator(this.functions, 'localhost', 5001)
    }
   }

  async getPaymentLink(data:any) {
    const generatePaymentLink = httpsCallable(this.functions, 'generatePaymentlink');
    return generatePaymentLink(data);
    /* const res = await generatePaymentLink({});
    console.log("was ist res", res); */
    
  }
  async health() {
    const generatePaymentLink = httpsCallable(this.functions, 'health');
    const res = await generatePaymentLink({});
    console.log("was ist res", res); 
  }

  async getOfficeSubscription() {
    const getSubscriptionFromOwner = httpsCallable(this.functions, 'getOfficeSubscription');
    return getSubscriptionFromOwner({});
  }

  async createStripeSession(product:any) {
    const createStripeSession = httpsCallable(this.functions, 'stripe-createStripeSession');
    return createStripeSession(product);
  }
  async getAllProducts() {
    const listAllProducts = httpsCallable(this.functions, 'stripe-listAllProducts');
    return listAllProducts({});
  }

  getProductPrice(priceId: any) {
    const getProductPrice = httpsCallable(this.functions, 'stripe-getProductPrice');
    return getProductPrice({priceId: priceId});
  }

  createPortalLink() {
    const createPortalLink = httpsCallable(this.functions, 'ext-firestore-stripe-payments-createPortalLink');
    return createPortalLink({
      returnUrl: window.location.origin + "/account/abonnements",
      locale: "de", // Optional, defaults to "auto"
      //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
  }

  inviteEmployee(email: string, officeId: string) {
    const inviteEmployee = httpsCallable(this.functions, 'inviteEmployee');
    return inviteEmployee({email: email, officeId: officeId});
  }
}
